exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-apps-duolingo-practice-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/apps/duolingo-practice.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-apps-duolingo-practice-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-apps-easy-pace-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/apps/easy-pace.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-apps-easy-pace-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-apps-perfect-password-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/apps/perfect-password.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-apps-perfect-password-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-about-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/pages/about.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-about-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/pages/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-privacy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-privacy-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-terms-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/pages/terms.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-terms-mdx" */)
}

